import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>NOT FOUND</h1>
      <p>Unfortunately the page was not found. Check the URL, or go to <a href="http://www.blazemagic.com">Blaze Magic main page</a> </p>
    </div>
  </Layout>
)

export default NotFoundPage
